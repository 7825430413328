<template>
  <div
    class="mx-auto container bg-white min-h-screen font-inter py-8 sm:py-15.5 md:py-15 px-8 md:px-20 xl:px-24 2xl:px-30"
  >
    <div v-for="(policy, policyKey) in policies" :key="policyKey">
      <section>
        <div>
          <div
            v-if="policy.title"
            :id="policy.id"
            v-i18n="landingPage"
            class="font-bold text-rich-black xs:leading-8 text-1xl leading-8 sm:text-2xl md:text-4xl lg:text-5xl text-center pb-5 lg:pb-10"
          >
            {{ policy.title }}
          </div>
          <div
            v-if="policy.mainPoint"
            v-i18n="landingPage"
            class="font-normal text-sm md:text-base leading-7 text-text-grey lg:text-justify"
          >
            {{ policy.mainPoint }}
          </div>
          <div
            v-if="policy.subHeading"
            v-i18n="landingPage"
            class="font-semibold text-sm md:text-base leading-7 lg:text-justify"
          >
            {{ policy.subHeading }}
          </div>
          <div v-for="(description, descriptionKey) in policy.description" :key="descriptionKey">
            <div
              v-if="policy.heading"
              v-i18n="landingPage"
              class="font-medium md:text-lg text-base leading-7 pt-3 md:pt-4 text-text-grey lg:text-justify"
            >
              {{ policy.heading[descriptionKey] }}
            </div>
            <div
              v-if="policy.subTitle"
              v-i18n="landingPage"
              class="text-lg leading-34 font-bold lg:text-justify"
            >
              {{ policy.subTitle[descriptionKey] }}
            </div>
            <p
              v-if="typeof description === 'string'"
              class="font-normal md:text-lg md:leading-34 mb-3 text-sm text-dark-silver lg:text-justify"
            >
              {{ $t(`landingPage.${description}`) }}
            </p>
            <div v-else class="mb-3 md:mb-5 lg:mb-8 lg:text-justify">
              <p
                v-for="(subDescription, subDescriptionKey) in description"
                :key="subDescriptionKey"
                class="font-normal md:text-lg text-sm text-dark-silver"
              >
                {{ $t(`landingPage.${subDescription}`) }}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import policiesData from '@src/router/views/privacy-policy/privacy-policy.json'

const landingPage = ref('landingPage')
const policies = ref(policiesData.policies)
</script>
